<template>
    <div class="block-box">
        <div class="block-item">
            <div class="banner">
                <el-carousel trigger="click" :interval="4000" :loop="false" :height="banner1Height + 'px'">
                    <el-carousel-item v-for="(item,index) in banner1List" :key="index">
                        <img class="banner-img" :src="item.pic_url" />
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="block-item">
            <div class="block-title">Featured Categories</div>
            <div class="list1">
                <div v-for="(item,index) in category" :key="index" v-if="index < 8" class="list1-item" @click="toProduct(item.id)">
                    <img class="goods-img" :src="item.logo_url" />
                    <div class="category-name">{{item.name}}</div>
                </div>
            </div>
        </div>
        <div class="block-item">
            <div class="block-title">Hot Sales</div>
            <div class="list2">
                <div v-for="(item,index) in data.hot" :key="index" class="list2-item">
                    <div class="click-info" @click="toDetail(item.id)">
                        <img class="img" :src="item.pic_url" />
                        <div class="name">{{item.good_title}}</div>
                    </div>
                    <div class="price">{{$currencySymbol}}{{item.price_trans}}</div>
                </div>
            </div>
        </div>
        <div class="block-item">
            <div class="banner">
                <el-carousel trigger="click" :interval="4000" :loop="false" :height="banner2Height + 'px'">
                    <el-carousel-item v-for="(item,index) in banner2List" :key="index">
                        <img class="banner-img" :src="item.pic_url" />
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <!-- <div class="block-item">
            <div class="block-title">Top Sellers</div>
            <div class="list3-box">
                <div v-if="curIndex > 0" class="previous-btn" @click="previous">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div v-if="curIndex < (data.top.length - 1)" class="next-btn" @click="next">
                    <i class="el-icon-arrow-right"></i>
                </div>
                <div class="list3" :style="`transform: translateX(-${25.33 * curIndex}%)`">
                    <div v-for="(item,index) in data.top" :key="index" class="list3-item">
                        <div class="click-info" @click="toDetail(item.id)">
                            <img class="img" :src="item.pic_url" />
                            <div class="name">{{item.good_title}}</div>
                        </div>
                        <div class="price">{{$currencySymbol}}{{item.price_trans}}</div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data: {
                    banner: [],
                    hot: [],
                    top: []
                },
                banner1List: [],
                banner2List: [],
                banner1Height: 0,
                banner2Height: 0,
                bannerWidth: 0,
                curIndex: 0
            }
        },
        created() {
            this.getHome()
        },
        mounted() {
            // 首次加载时,需要调用一次
            this.setSize()
            // 窗口大小发生改变时,调用一次
            window.onresize = () => {
                this.setSize()
            }
        },
        computed: {
            category() {
                return this.$store.state.category
            }
        },
        methods: {
            getHome() {
                this.$api.home().then(res => {
                    this.data = res.data
                    this.banner1List = this.data.banner.filter(item => item.type == 1)
                    this.banner2List = this.data.banner.filter(item => item.type == 2)
                })
            },
            
            setSize() {
                // 获取Banner宽度
                this.bannerWidth = document.getElementsByClassName('block-item')[0].clientWidth
                // 获取Banner高度
                this.banner1Height = (1 / 2) * this.bannerWidth
                this.banner2Height = (1 / 4) * this.bannerWidth
            },
            
            previous() {
                this.curIndex--
            },
            
            next() {
                this.curIndex++
            },
            
            toProduct(category_id) {
                this.$router.push({
                    path: '/product',
                    query: {
                        category_id
                    }
                })
            },
            
            toDetail(id) {
                this.$router.push({
                    path: '/detail',
                    query: {
                        id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .block-box {
            width: 100%;
            
            .block-item {
                width: 100%;
                padding-bottom: 5rem;
                
                .banner {
                    width: 100%;
                    
                    .banner-img {
                        width: 100%;
                        height: inherit;
                    }
                }
                
                .block-title {
                    width: 100%;
                    padding: .8rem 0;
                    margin-bottom: 3rem;
                    font-size: 3rem;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .list1 {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    
                    .list1-item {
                        position: relative;
                        width: 24%;
                        height: 0;
                        padding-bottom: 24%;
                        margin-left: 1.33%;
                        overflow: hidden;
                        cursor: pointer;
                        
                        &:nth-child(4n+1) {
                            margin-left: 0;
                        }
                        
                        &:nth-child(n+5) {
                            margin-top: 1.6rem;
                        }
                        
                        .goods-img {
                            display: block;
                            width: 100%;
                        }
                        
                        .category-name {
                            position: absolute;
                            bottom: 2rem;
                            left: 50%;
                            transform: translateX(-50%);
                            max-width: calc(100% - 2rem);
                            height: 4.4rem;
                            line-height: 4.4rem;
                            box-sizing: border-box;
                            padding: 0 2rem;
                            border-radius: 6px;
                            font-size: 1.4rem;
                            color: #191919;
                            text-align: center;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            background: rgba(255, 255, 255, 0.9);
                            transition: color 0.2s linear;
                            
                            &:hover {
                                color: #FFFFFF;
                                background-color: #000000;
                            }
                        }
                    }
                }
                
                .list2 {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    
                    .list2-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 24%;
                        margin-left: 1.33%;
                        
                        &:nth-child(4n+1) {
                            margin-left: 0;
                        }
                        
                        &:nth-child(n+5) {
                            margin-top: 1.6rem;
                        }
                        
                        .click-info {
                            width: 100%;
                            
                            .img {
                                display: block;
                                width: 100%;
                                cursor: pointer;
                            }
                            
                            .name {
                                width: 100%;
                                padding: 1.4rem 0 1rem;
                                font-size: 1.4rem;
                                color: #000000;
                                text-align: center;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                cursor: pointer;
                                
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                        
                        .price {
                            width: 100%;
                            font-size: 1.6rem;
                            font-weight: bold;
                            color: #000000;
                            text-align: center;
                            word-break: break-all;
                        }
                    }
                }
                
                .list3-box {
                    position: relative;
                    width: 100%;
                    overflow-x: hidden;
                    
                    &:hover {
                        .previous-btn,
                        .next-btn {
                            color: rgba(102,102,102,1);
                            background: rgba(255,255,255,1);
                        }
                        
                        .previous-btn {
                            left: 1rem;
                        }
                        
                        .next-btn {
                            right: 1rem;
                        }
                    }
                    
                    .previous-btn,
                    .next-btn {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 2;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 5rem;
                        height: 5rem;
                        border-radius: .5rem;
                        font-size: 2.8rem;
                        color: rgba(102,102,102,5);
                        white-space: nowrap;
                        transition: all 0.3s ease;
                        background: rgba(255,255,255,.5);
                        cursor: pointer;
                        
                        i {
                            font-weight: bold;
                        }
                    }
                    
                    .previous-btn {
                        left: 2.5rem;
                    }
                    
                    .next-btn {
                        right: 2.5rem;
                    }
                    
                    .list3 {
                        display: flex;
                        width: 100%;
                        transition: all 0.3s ease;
                        
                        .list3-item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            min-width: 24%;
                            max-width: 24%;
                            
                            &:not(:last-child) {
                                margin-right: 1.33%;
                            }
                            
                            .click-info {
                                width: 100%;
                                
                                .img {
                                    display: block;
                                    width: 100%;
                                    cursor: pointer;
                                }
                                
                                .name {
                                    width: 100%;
                                    padding: 1.4rem 0 1rem;
                                    font-size: 1.4rem;
                                    color: #000000;
                                    text-align: center;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    cursor: pointer;
                                    
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                            
                            .price {
                                width: 100%;
                                font-size: 1.6rem;
                                font-weight: bold;
                                color: #000000;
                                text-align: center;
                                word-break: break-all;
                            }
                        }
                    }
                }
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .block-box {
            width: 100%;
            
            .block-item {
                width: 100%;
                padding-bottom: 3rem;
                
                .banner {
                    width: 100%;
                    
                    .banner-img {
                        width: 100%;
                        height: inherit;
                    }
                }
                
                .block-title {
                    width: 100%;
                    box-sizing: border-box;
                    padding: .6rem $h5-box-padding;
                    margin-bottom: 2rem;
                    font-size: 2rem;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .list1 {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 $h5-box-padding;
                    
                    .list1-item {
                        position: relative;
                        width: 48%;
                        height: 0;
                        padding-bottom: 48%;
                        margin-left: 4%;
                        overflow: hidden;
                        cursor: pointer;
                        
                        &:nth-child(2n+1) {
                            margin-left: 0;
                        }
                        
                        &:nth-child(n+3) {
                            margin-top: 1.5rem;
                        }
                        
                        .goods-img {
                            display: block;
                            width: 100%;
                        }
                        
                        .category-name {
                            position: absolute;
                            bottom: 1.5rem;
                            left: 50%;
                            transform: translateX(-50%);
                            max-width: calc(100% - 1.6rem);
                            height: 3.5rem;
                            line-height: 3.5rem;
                            box-sizing: border-box;
                            padding: 0 1.5rem;
                            border-radius: 4px;
                            font-size: 1.4rem;
                            color: #191919;
                            text-align: center;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            background: rgba(255, 255, 255, 0.9);
                            transition: color 0.2s linear;
                            
                            &:hover {
                                color: #FFFFFF;
                                background-color: #000000;
                            }
                        }
                    }
                }
                
                .list2 {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 $h5-box-padding;
                    
                    .list2-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 48%;
                        margin-left: 4%;
                        
                        &:nth-child(2n+1) {
                            margin-left: 0;
                        }
                        
                        &:nth-child(n+3) {
                            margin-top: 1.5rem;
                        }
                        
                        .click-info {
                            width: 100%;
                            
                            .img {
                                display: block;
                                width: 100%;
                                cursor: pointer;
                            }
                            
                            .name {
                                width: 100%;
                                padding: 1.2rem 0 .8rem;
                                font-size: 1.4rem;
                                color: #000000;
                                text-align: center;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                cursor: pointer;
                                
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                        
                        .price {
                            width: 100%;
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: #000000;
                            text-align: center;
                            word-break: break-all;
                        }
                    }
                }
                
                .list3-box {
                    position: relative;
                    width: 100%;
                    box-sizing: border-box;
                    // padding: 0 $h5-box-padding;
                    overflow-x: hidden;
                    
                    .previous-btn,
                    .next-btn {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 2;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 4rem;
                        height: 4rem;
                        border-radius: .4rem;
                        font-size: 2.2rem;
                        color: #333333;
                        white-space: nowrap;
                        transition: all 0.3s ease;
                        background: #eeeeee;
                        cursor: pointer;
                        
                        i {
                            font-weight: bold;
                        }
                    }
                    
                    .previous-btn {
                        left: 1.5rem;
                    }
                    
                    .next-btn {
                        right: 1.5rem;
                    }
                    
                    .list3 {
                        display: flex;
                        width: 100%;
                        transition: all 0.3s ease;
                        
                        .list3-item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            min-width: 24%;
                            max-width: 24%;
                            
                            &:not(:last-child) {
                                margin-right: 1.33%;
                            }
                            
                            .click-info {
                                width: 100%;
                                
                                .img {
                                    display: block;
                                    width: 100%;
                                    cursor: pointer;
                                }
                                
                                .name {
                                    width: 100%;
                                    padding: 1.2rem 0 .8rem;
                                    font-size: 1.4rem;
                                    color: #000000;
                                    text-align: center;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    cursor: pointer;
                                    
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                            
                            .price {
                                width: 100%;
                                font-size: 1.5rem;
                                font-weight: bold;
                                color: #000000;
                                text-align: center;
                                word-break: break-all;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
